// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-infor {
  max-width: 800px;
}
.group-infor-content {
  max-width: 500px;
}
.group-member-img {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/Group.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;AAAJ;AACI;EACE,gBAAA;AACN;AAEE;EACE,YAAA;AAAJ","sourcesContent":[".group {\n  &-infor {\n    max-width: 800px;\n    &-content {\n      max-width: 500px;\n    }\n  }\n  &-member-img {\n    width: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
