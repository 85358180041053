import React from "react";

class NotFound extends React.Component {
  //function

  //render html
  render() {
    return <div className="text-center my-3">this is a NotFound</div>;
  }
}

export default NotFound;
