import React from "react";
import { Link } from "react-router-dom";
import "./SeriesShared.scss";
class SeriesFooter extends React.Component {
  //function
  //render html
  render() {
    return (
      <footer className="series-footer">
        <div className="container p-0 pt-3 py-lg-3">
          <div className="d-flex justify-content-center justify-content-md-between">
            <Link to="/" className="d-none d-md-flex align-items-center">
              <img
                src="https://i.imgur.com/BsSFmsd.png"
                alt="叫我野孩子"
                className="footer-logo"
              />
            </Link>
            <ul className="ps-0 mb-0 d-lg-flex flex-md-row-reverse align-items-center">
              <li className="text-white mb-2 mb-lg-0">
                合作、聯繫請洽 :
                <a
                  href="mailto:callmewildchild.official@gmail.com"
                  className="word-wrap text-white text-decoration-none ms-2 a-hover"
                >
                  callmewildchild.official@gmail.com
                </a>
              </li>
              <li className="text-white pe-md-2 mb-3 mb-lg-0">
                <span className="d-none d-md-inline">Copyright</span>©Lumian
                Production House All rights reserved.
              </li>
            </ul>
          </div>
        </div>
      </footer>
    );
  }
}

export default SeriesFooter;
