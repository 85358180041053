// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.font-content {
  font-family: "Noto Sans TC", sans-serif;
  font-size: 20px;
}

.a-hover:hover {
  color: #b5d153 !important;
}

.series,
.group {
  background: url(https://i.imgur.com/P4kvynd.png) repeat;
  background-size: 50%;
}
.series-header,
.group-header {
  background: url(https://i.imgur.com/OPp7VrD.png) center repeat-x;
}
.series .main-header,
.group .main-header {
  padding-bottom: 20px;
}
@media (min-width: 414px) {
  .series .main-header,
  .group .main-header {
    padding-top: 5px;
  }
}
@media (max-width: 414px) {
  .series .main-header .navbar-expand .navbar-nav .nav-link,
  .group .main-header .navbar-expand .navbar-nav .nav-link {
    padding-left: 0;
  }
}
.series-page .main-header,
.group-page .main-header {
  padding-top: 0;
  padding-bottom: 0;
}
.series-footer,
.group-footer {
  background: url(https://i.imgur.com/UhGbZnH.png) center left repeat-x;
  font-size: 14px;
}
.series-footer .footer-logo,
.group-footer .footer-logo {
  max-width: 180px;
  height: auto;
}
@media (max-width: 414px) {
  .series-footer,
  .group-footer {
    font-size: 13px;
  }
}

.border-radius-20 {
  border-radius: 20px;
}

.custom-word-wrap {
  display: none;
}
@media (max-width: 430px) {
  .custom-word-wrap {
    display: block;
    text-align: right;
    white-space: normal;
  }
}`, "",{"version":3,"sources":["webpack://./src/SeriesShared.scss"],"names":[],"mappings":"AAAA;EACE,uCAAA;EACA,eAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;;EAsBE,uDAAA;EACA,oBAAA;AAnBF;AAFE;;EACE,gEAAA;AAKJ;AAHE;;EACE,oBAAA;AAMJ;AALI;EAFF;;IAGI,gBAAA;EASJ;AACF;AAPM;EADF;;IAEI,eAAA;EAWN;AACF;AAPI;;EACE,cAAA;EACA,iBAAA;AAUN;AAJE;;EACE,qEAAA;EAKA,eAAA;AAGJ;AAPI;;EACE,gBAAA;EACA,YAAA;AAUN;AAPI;EAPF;;IAQI,eAAA;EAWJ;AACF;;AAPA;EACE,mBAAA;AAUF;;AAPA;EACE,aAAA;AAUF;AATE;EAFF;IAGI,cAAA;IACA,iBAAA;IACA,mBAAA;EAYF;AACF","sourcesContent":[".font-content {\n  font-family: \"Noto Sans TC\", sans-serif;\n  font-size: 20px;\n}\n\n.a-hover:hover {\n  color: #b5d153 !important;\n}\n\n.series,\n.group {\n  &-header {\n    background: url(https://i.imgur.com/OPp7VrD.png) center repeat-x;\n  }\n  .main-header {\n    padding-bottom: 20px;\n    @media (min-width: 414px) {\n      padding-top: 5px;\n    }\n    .navbar-expand .navbar-nav .nav-link {\n      @media (max-width: 414px) {\n        padding-left: 0;\n      }\n    }\n  }\n  &-page {\n    .main-header {\n      padding-top: 0;\n      padding-bottom: 0;\n    }\n  }\n  background: url(https://i.imgur.com/P4kvynd.png) repeat;\n  background-size: 50%;\n\n  &-footer {\n    background: url(https://i.imgur.com/UhGbZnH.png) center left repeat-x;\n    .footer-logo {\n      max-width: 180px;\n      height: auto;\n    }\n    font-size: 14px;\n    @media (max-width: 414px) {\n      font-size: 13px;\n    }\n  }\n}\n\n.border-radius-20 {\n  border-radius: 20px;\n}\n\n.custom-word-wrap {\n  display: none;\n  @media (max-width: 430px) {\n    display: block;\n    text-align: right;\n    white-space: normal;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
