// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.travel-header .navbar {
  background: rgba(0, 0, 0, 0.3);
}
.travel-header .img-hide {
  text-indent: 110%;
  overflow: hidden;
  white-space: nowrap;
  float: left;
}
.travel-header .brand {
  background: url(https://i.imgur.com/4v8rDfz.png) no-repeat center center/75%;
  width: 250px;
  height: 68px;
}
.travel-header .nav-item:hover {
  transform: scale(1.1);
}
.travel-header .nav-item .nav-link {
  width: 165px;
  height: 26px;
}
@media (min-width: 768px) {
  .travel-header .nav-item .nav-link {
    width: 130px;
  }
}
@media (min-width: 840px) {
  .travel-header .nav-item .nav-link {
    width: 145px;
  }
}
@media (min-width: 1080px) {
  .travel-header .nav-item .nav-link {
    width: 165px;
  }
}
.travel-header .nav-hualian {
  background: url(https://i.imgur.com/fLjtw67.png) no-repeat center center/100%;
}
.travel-header .nav-penghu {
  background: url(https://i.imgur.com/wIKwJYX.png) no-repeat center center/100%;
}
.travel-header .nav-lanyu {
  background: url(https://i.imgur.com/nyrnCpS.png) no-repeat center center/80%;
}
.travel-header .nav-qigu {
  background: url(https://i.imgur.com/CA4Wsyg.png) no-repeat center center/80%;
}`, "",{"version":3,"sources":["webpack://./src/Travel/TravelHeader.scss"],"names":[],"mappings":"AACE;EACE,8BAAA;AAAJ;AAGE;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,WAAA;AADJ;AAIE;EACE,4EAAA;EAEA,YAAA;EACA,YAAA;AAHJ;AAOI;EACE,qBAAA;AALN;AAOI;EACE,YAAA;EACA,YAAA;AALN;AAMM;EAHF;IAII,YAAA;EAHN;AACF;AAIM;EANF;IAOI,YAAA;EADN;AACF;AAEM;EATF;IAUI,YAAA;EACN;AACF;AAGE;EACE,6EAAA;AADJ;AAKE;EACE,6EAAA;AAHJ;AAOE;EACE,4EAAA;AALJ;AASE;EACE,4EAAA;AAPJ","sourcesContent":[".travel-header {\n  .navbar {\n    background: rgba($color: #000000, $alpha: 0.3);\n  }\n\n  .img-hide {\n    text-indent: 110%;\n    overflow: hidden;\n    white-space: nowrap;\n    float: left;\n  }\n\n  .brand {\n    background: url(https://i.imgur.com/4v8rDfz.png) no-repeat center center /\n      75%;\n    width: 250px;\n    height: 68px;\n  }\n\n  .nav-item {\n    &:hover {\n      transform: scale(1.1);\n    }\n    .nav-link {\n      width: 165px;\n      height: 26px;\n      @media (min-width: 768px) {\n        width: 130px;\n      }\n      @media (min-width: 840px) {\n        width: 145px;\n      }\n      @media (min-width: 1080px) {\n        width: 165px;\n      }\n    }\n  }\n\n  .nav-hualian {\n    background: url(https://i.imgur.com/fLjtw67.png) no-repeat center center /\n      100%;\n  }\n\n  .nav-penghu {\n    background: url(https://i.imgur.com/wIKwJYX.png) no-repeat center center /\n      100%;\n  }\n\n  .nav-lanyu {\n    background: url(https://i.imgur.com/nyrnCpS.png) no-repeat center center /\n      80%;\n  }\n\n  .nav-qigu {\n    background: url(https://i.imgur.com/CA4Wsyg.png) no-repeat center center /\n      80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
