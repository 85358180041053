import React from "react";
import "./TravelShared.scss";
import "./PenghuPage.scss";
import Header from "./TravelHeader";
import Footer from "./TravelFooter";
import GoToTop from "../GoToTop";
import ReactPlayer from "react-player";
class PenghuPage extends React.Component {
  //function

  //render html
  render() {
    return (
      <div className="travel">
        <Header />
        <main className="penghu">
          <section className="header-video-container container-fluid vh-100 px-0 mb-5">
            <ReactPlayer
              url="https://www.youtube.com/embed/6MAcpgrxBug?&autoplay=1&mute=1&controls=0&rel=1&loop=1&playlist=6MAcpgrxBug"
              playing="true"
              muted="true"
              loop="true"
            />
            <h2>
              <img
                src="https://i.imgur.com/Y19tA0O.png"
                alt="讓孩子跟大海學遼闊 澎湖親子團"
                className="top-banner-title position-absolute top-50 start-50"
              />
            </h2>
          </section>

          <section className="container">
            <section className="row flex-md-row-reverse align-items-md-center mb-3 mb-md-0">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/6PRtWEM.png"
                    alt="找回遺失的五感"
                    className="subtitle"
                  />
                </h2>
                <p className="content">
                  大自然有神奇的魅力，它能拓展我們的視野、開闊我們的胸襟。帶著孩子到澎湖一探究竟吧，這裡不只是整理好的海水浴場和海鮮餐廳，而是得花盡力氣才能收穫到的一點點幸福滋味。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/873rHXb.png"
                  alt="penghu-img1"
                  className="w-100 f-100"
                />
              </div>
            </section>
            <section className="row align-items-md-center">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/szE6myl.png"
                    alt="會呼吸的潮間帶"
                    className="subtitle"
                  />
                </h2>
                <p className="content">
                  潮間帶和沙灘非常不一樣，你得熟悉漲退潮才能和它做朋友。你還得認識它的地形，在珊瑚礁、礫石和泥沙裡能採集到的生物都不盡相同。和孩子一起背起魚簍當個小偵探吧，潮間帶絕對不會讓你失望的。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/avO6V4r.png"
                  alt="penghu-img1"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <section className="container-fluid px-0 py-3 py-md-0 banner-bg d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col col-md-8">
                  <h2>
                    <img
                      src="https://i.imgur.com/g0KgXGr.png"
                      alt="banner-title"
                      className="w-75"
                    />
                  </h2>
                  <p className="content text-white">
                    聽過海上也有萬里長城嗎？在海上綿延幾公里長的石滬會讓你大開眼界。走進世界文化遺產，和孩子在石滬裡游泳、看魚兒跳耀，體驗漁民的古老智慧。
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container mb-5">
            <section className="row align-items-md-center">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/54S5ouB.png"
                    alt="古老魚法親手體驗"
                    className="subtitle"
                  />
                </h2>
                <p className="content pe-md-5">
                  有聽過「牽罟」這種傳統漁法嗎？幾十個人在沙灘邊奮力拉起一張網，網子裡有各種海洋生物，保證會讓你驚叫連連。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/nQituh9.png"
                  alt="penghu-img3"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <div className="mb-5">
            <img
              src="https://i.imgur.com/TkXLJhP.png"
              alt="split-line"
              className="w-100"
            />
          </div>

          <section className="container-fluid px-0 mb-5 text-center">
            <div className="container">
              <h2 className="d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/SzraYIz.png"
                  alt="夜晚沙灘烤魚"
                  className="subtitle"
                />
              </h2>
              <p className="content">
                用木柴升起的火光是會讓人目不轉睛的，聲音、氣味、溫度都散發著奧秘，
                <br className="d-none d-lg-block" />
                在海邊升起一堆火一起烤魚，那是非常魔幻的一刻。
              </p>
            </div>
            <iframe
              className="w-100 vh-100 video1"
              src="https://www.youtube.com/embed/st2tvqP_cjg"
              title="penghu video 1"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </section>

          <section className="container mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/1dINU7W.png"
                alt="行程規劃"
                className="travel-title mb-3"
              />
            </h2>
            <ul className="ps-0 travel-list">
              <li className="travel-bg-1 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 1 <span>海島澎湖初體驗</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">安頓身心</h4>
                      <p className="pe-3">
                        民宿會安排接機，抵達溫馨的住所後租好車輛，吃過午餐就準備展開澎湖探索之旅。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">走讀潮間帶</h4>
                      <p className="pe-3">
                        來到紅羅村，教練會帶大家認識紅羅村的潮間帶、體驗抱墩漁法、參加有活化石之稱的尋「鱟」體驗。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">在海中央夜釣小管</h4>
                      <p className="pe-3">
                        晚上搭船出去釣小管，船上有美味的麵線晚餐，皎潔的月光相伴，夜晚的大海特別安靜。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-2 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 2 <span>開始學當討海人</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">牽罟-傳統漁法</h4>
                      <p className="pe-3">
                        牽罟是澎湖先民最古老的捕魚方式之一，小船開出去灑網，再靠大家一起賣力把網子拉上來。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">抱墩收成</h4>
                      <p className="pe-3">
                        漲潮時魚會游到抱墩的陷阱裡，退潮時就有機會補到魚，還可以拿到修復好的魚灶去烹調。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">逛村落學木工</h4>
                      <p className="pe-3">
                        想要深入澎湖，就要走進社區看看討海人怎麼生活，還可以和匠師學木工。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-3 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 3 <span>學澎湖人過日子</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">和魚一起在石滬游泳</h4>
                      <p className="pe-3">
                        教練會帶大家到澎湖著名的三連滬游泳，在石滬裡有機會和魚共舞，還會和石滬空拍合影。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">自由行時段</h4>
                      <p className="pe-3">
                        建議前往二崁老宅、跨海大橋、小門鯨魚洞、玄武岩大果葉、外垵三仙塔、漁翁島燈塔。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">沙灘生火烤魚</h4>
                      <p className="pe-3">
                        在紅羅海邊搭起簡單帳篷，撿柴生火準備烤魚，聽聽澎湖人討海的故事，享受這魔幻的時刻。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-4 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 4 <span>最後巡禮</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-6">
                      <h4 className="h5">浮潛（自費行程，自由參加)</h4>
                      <p className="pe-3">
                        澎湖海面下深藏著珊瑚美景和漂亮魚群，即使不會游泳，也有機會去親近大海裡的神奇世界。
                        <br />
                        <a
                          href="https://www.goodaydive.com/"
                          target="_blank"
                          title="日日潛水"
                          className="content-link"
                          rel="noreferrer"
                        >
                          日日潛水-澎湖縣馬公市68-2號
                        </a>
                        <br />
                        <a href="tel:+886937168722" className="content-link">
                          0937 168 722
                        </a>
                      </p>
                    </li>
                    <li className="col-12 col-lg-6">
                      <h4 className="h5">回程</h4>
                      <p className="pe-3">歸還車輛，準備回到溫暖的家</p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </section>

          <section className="container-fluid mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/o7tdrCI.png"
                alt="活動精彩瞬間"
                className="subtitle"
              />
            </h2>
            <ul className="d-none d-md-flex row ps-0">
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/KOsEEX9.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>

              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/UQ3yRVt.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/ULa5WKB.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/xC3YbIN.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/fUVe4ML.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/nhoh2WJ.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/k6Bz9bG.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/iC08S63.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/GtrxtmG.jpg"
                  alt="澎湖親子團活動照片"
                  className="w-100"
                />
              </li>
            </ul>
            <div
              id="activityCarouselControls"
              className="carousel slide d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/KOsEEX9.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/UQ3yRVt.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/ULa5WKB.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/xC3YbIN.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/fUVe4ML.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/nhoh2WJ.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/k6Bz9bG.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/iC08S63.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
                <div className="carousel-item active">
                  <img
                    src="https://i.imgur.com/GtrxtmG.jpg"
                    className="d-block w-100"
                    alt="澎湖親子團活動照片"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </section>

          <section className="infor-wrap">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/LdmlyrL.png"
                alt="活動資訊"
                className="infor-title"
              />
            </h2>
            <div className="container-fluid infor-bg">
              <div className="container">
                <ul className="infor-content py-5 ps-0">
                  <li className="mb-3 mb-md-5">
                    <h3>費用說明</h3>
                    <p>
                      一人4150元（包含活動體驗費，不含機票、住宿、租車、三餐、自費行程）
                      <br />
                      （以下建議以靠近體驗地點湖西鄉為主，也可以自由選擇）
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>交通建議</h3>
                    <p>
                      禾一租車
                      <br />
                      <a
                        href="https://goo.gl/maps/tC7WzWSxxqmwQCHR8"
                        className="content-link"
                      >
                        澎湖縣湖西鄉湖西村77-20號
                      </a>
                      <br />
                      <a href="tel:+886905561939" className="content-link">
                        0905 561 939
                      </a>
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>餐食建議</h3>
                    <ul className="ps-0">
                      <li className="d-flex">
                        <p className="me-2">Day1</p>
                        <div>
                          <div className="d-flex flex-column flex-md-row">
                            中餐：
                            <p className="mb-0">阿秋飲食館-</p>
                            <a
                              href="https://www.google.com/maps/place/%E9%98%BF%E7%A7%8B%E9%A3%B2%E9%A3%9F%E5%BA%97/@23.5832016,119.6404447,17z/data=!3m1!4b1!4m5!3m4!1s0x346c44ef00000001:0x4148702d1a9caf70!8m2!3d23.5832016!4d119.6426334"
                              className="content-link"
                            >
                              澎湖縣湖西鄉西溪村135號
                            </a>
                            <a
                              href="tel:+069923326"
                              className="content-link d-block d-md-inline ms-md-2"
                            >
                              06 9923326
                            </a>
                          </div>
                          <p className="mb-2">晚餐：船上有麵線餐</p>
                        </div>
                      </li>
                      <li className="d-flex">
                        <p className="me-2">Day2</p>
                        <div className="mb-2">
                          <div className="d-flex flex-column flex-md-row">
                            中餐：
                            <p className="mb-0">傻愛莊-</p>
                            <a
                              href="https://www.google.com/maps/place/880%E6%BE%8E%E6%B9%96%E7%B8%A3%E9%A6%AC%E5%85%AC%E5%B8%82%E6%96%B0%E7%94%9F%E8%B7%AF14%E8%99%9F/@23.567744,119.5662199,17z/data=!3m1!4b1!4m5!3m4!1s0x346c5af279f9f02b:0x7f04a17c678d24cb!8m2!3d23.567744!4d119.5684086"
                              className="content-link"
                            >
                              澎湖縣馬公市新生路14號
                            </a>
                            <a
                              href="tel:+069263693"
                              className="content-link d-block d-md-inline ms-md-2"
                            >
                              06 9263693
                            </a>
                          </div>
                          <div className="d-flex flex-column flex-md-row">
                            晚餐：
                            <p className="mb-0">湖東釜鍋便當-</p>
                            <a
                              href="https://goo.gl/maps/gehkN7LevWMwEZmq5"
                              className="content-link"
                            >
                              澎湖縣湖西鄉湖東村23-1號
                            </a>
                            <a
                              href="tel:+886913696108"
                              className="content-link d-block d-md-inline ms-md-2"
                            >
                              0913 696 108
                            </a>
                          </div>
                        </div>
                      </li>
                      <li className="d-flex">
                        <p className="me-2 mb-0">Day3</p>
                        <div className="mb-0">
                          <div className="d-flex flex-column flex-md-row">
                            中餐：
                            <p className="mb-0">小島家早午餐-</p>
                            <a
                              href="https://goo.gl/maps/hvVinfep5pEJccdT7"
                              className="content-link"
                            >
                              澎湖縣馬公市中山路8號
                            </a>
                            <a
                              href="tel:+886933868639"
                              className="content-link d-block d-md-inline ms-md-2"
                            >
                              0933 868 639
                            </a>
                          </div>
                          <p>晚餐：沙灘烤魚</p>
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>住宿建議</h3>
                    <div>
                      好澎友民宿（住宿費含早餐）
                      <br />
                      <a
                        href="https://goo.gl/maps/wKrSdo4BHoaWYv4b6"
                        className="content-link"
                      >
                        澎湖縣湖西鄉77-21號
                      </a>
                      <br />
                      <a href="tel:+069921138" className="content-link">
                        069921138
                      </a>
                    </div>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>活動注意事項</h3>
                    <ul className="ps-0">
                      <li className="d-flex">
                        <p className="mb-0">1、</p>
                        <p className="mb-0">
                          澎湖夏季陽光毒辣，為響應環保，建議以「物理性防曬」為主，如：自備遮陽帽、袖套等。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">2、</p>
                        <p className="mb-0">
                          主題旅遊費用包含依照旅行業規定投保旅責險。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">3、</p>
                        <p className="mb-0">
                          主辦單位保留修改、終止、調整活動內容細節之權利。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">4、</p>
                        <p className="mb-0">
                          如遇天災或氣候等不可抗力因素，主辦單位將在活動日前一天主動聯繫延期或退款。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">5、</p>
                        <p className="mb-0">
                          相關活動將由主辦單位進行側拍攝影，並於活動後上傳至「離島出走
                          isle.travel」相關社群平台，作為紀錄與宣傳等用途，如不希望被側錄，可於活動現場告知工作人員，我們將請當日工作人員注意並特別安排。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">6、</p>
                        <p className="mb-0">
                          如有其他問題，請來電：0978-881955，將有專人為您服務。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 mb-md-5 d-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <h3>承辦單位：離島出走</h3>
                    <a
                      href="https://reurl.cc/9GEKdY"
                      target="_blank"
                      className="btn-join btn-hover"
                      rel="noreferrer"
                    >
                      <img src={require("../img/join.png")} alt="我要報名" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        <GoToTop />
      </div>
    );
  }
}

export default PenghuPage;
