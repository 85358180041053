import React from "react";
import "./TravelShared.scss";
import "./LanyuPage.scss";
import Header from "./TravelHeader";
import Footer from "./TravelFooter";
import GoToTop from "../GoToTop";
import ReactPlayer from "react-player";
class LanyuPage extends React.Component {
  //function

  //render html
  render() {
    return (
      <div className="travel">
        <Header />
        <main className="lanyu">
          <section className="header-video-container container-fluid vh-100 px-0 mb-5">
            <ReactPlayer
              url="https://www.youtube.com/embed/3GxJSICIPZI?&autoplay=1&mute=1&controls=0&rel=1&loop=1&playlist=3GxJSICIPZI"
              playing="true"
              muted="true"
              loop="true"
            />
            <h2>
              <img
                src="https://i.imgur.com/YoH7LuP.png"
                alt="一生一定要來一次的蘭嶼 蘭嶼親子團"
                className="top-banner-title position-absolute top-50 start-50"
              />
            </h2>
          </section>

          <section className="container">
            <section className="row flex-md-row-reverse align-items-md-center mb-3 mb-md-0">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/Dc9BgFJ.png"
                    alt="開啟乘風破浪的冒險"
                    className="subtitle"
                  />
                </h2>
                <p className="content">
                  疫情時代出國非常困難，也許你此生本來沒有計畫要去一趟蘭嶼，現在是個大好機會。帶著孩子來趟蘭嶼冒險之旅，那裡的山和海有奇特的粗獷感，不管你會不會暈船，都值得你說嘴並回味一生。{" "}
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/BYficFT.png"
                  alt="lanyu-img1"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <section className="container-fluid px-0 py-3 py-md-0 my-5 my-sm-0 banner-bg d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col col-md-8">
                  <h2>
                    <img
                      src="https://i.imgur.com/Dno184K.png"
                      alt="和蘭嶼人一起生活"
                      className="w-50"
                    />
                  </h2>
                  <p className="content text-white">
                    蘭嶼並不總是風和日麗，進入冬天，風大雨大的蘭嶼像野獸，會吞噬你的靈魂。跟著達悟族的馬然夫妻學習生存之道，在山谷裡撿柴、在地下屋生火煮湯，絕對是永生難忘的回憶。
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container mb-5">
            <section className="row align-items-md-center">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/6PRtWEM.png"
                    alt="找回遺失的五感"
                    className="subtitle"
                  />
                </h2>
                <p className="content pe-md-5">
                  蘭嶼的達悟族有著獨特的生活態度，所需要的一切都得自己動手做。主食是芋頭，那就和孩子去芋頭田勞動；想吃魚，就自己去海邊釣。錢未必能買到一切，但自己動手收穫的絕對最甜美。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/xziAHgX.png"
                  alt="lanyu-img3"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <div className="mb-5">
            <img
              src="https://i.imgur.com/fChHMbU.png"
              alt="split-line"
              className="w-100"
            />
          </div>

          <section className="container-fluid px-0 mb-5 text-center">
            <div className="container">
              <h2 className="d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/gfhExxu.png"
                  alt="為自己做一支釣竿"
                  className="subtitle"
                />
              </h2>
              <p className="content">
                大海是蘭嶼人的冰箱，和大海搏鬥是他們的天命。但從台灣來的我們不用那麼拼命，
                <br className="d-none d-lg-block" />
                上山採竹子做根釣竿，親手釣到一條魚，就可以自詡為海洋之子了。
              </p>
            </div>
            <iframe
              className="w-100 vh-100 video1"
              src="https://www.youtube.com/embed/IA2hfR9nz1Y"
              title="lanyu video 1"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </section>

          <section className="container mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/1dINU7W.png"
                alt="行程規劃"
                className="travel-title mb-3"
              />
            </h2>
            <ul className="ps-0 travel-list">
              <li className="travel-bg-1 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 1 <span>蘭嶼初體驗</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">搭船去蘭嶼</h4>
                      <p className="pe-3">
                        十二點台東富岡漁港碼頭綠島之星櫃台領票，搭船抵達蘭嶼、租好摩托車後就準備展開蘭嶼探索之旅。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">直達朗島</h4>
                      <p className="pe-3">
                        從灘頭、船屋到傳統屋裡，介紹最真實的部落環境，分享達悟族的生活點滴跟生命智慧。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">來去部落家裡吃晚餐</h4>
                      <p className="pe-3">
                        晚餐前先到部落媽媽的家裡幫忙，跟著一起準備今晚的料理，吃的全是最在地的家常餐點。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-2 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 2 <span>走進蘭嶼的山與海</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-6">
                      <h4 className="h5">兒時的家務事</h4>
                      <p className="pe-3">
                        跟著部落大哥到人間仙境般的山谷散步、撿柴，從選柴、處理到起火，每一個都是生活經驗的累積。
                      </p>
                    </li>
                    <li className="col-12 col-lg-6">
                      <h4 className="h5">浮潛 (自費行程，自由參加)</h4>
                      <p className="pe-3">
                        在山上吃完部落媽媽準備的便當，也完成了撿柴的任務，下午就在浮潛教練的帶領下，一窺神秘的海底世界吧！
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-3 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 3 <span>學蘭嶼人過日子</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-3"
                  />
                  <ul className="row g-0">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">關於女人的海</h4>
                      <p className="pe-3">
                        跟著部落媽媽一起下田，地瓜、芋頭是島上最重要的作物，累了在涼台休息、吹吹海風。接著到傳統家屋裡用自己撿的木柴生火、蒸煮早上拔的地瓜芋頭。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">關於男人的海</h4>
                      <p className="pe-3">
                        上山砍竹子，跟著部落的大哥做釣竿然後到海邊釣魚，浪況不錯的話也有機會下海泡泡水，或是帶著一根螺絲起子尋找寶物，在潮間帶為今晚加菜。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">達悟的傳統便當</h4>
                      <p className="pe-3">
                        抓到魚在海邊先去鱗拿出內臟，整理好接著就回家準備晚餐。自己砍的柴火、自己抓得魚、自己煮的晚餐，晚餐就在蘭嶼最有特色的涼亭享用。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-4 row align-items-center mb-4">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 4 <span>最後巡禮</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col">
                      <h4 className="h5">留點空白給自己</h4>
                      <p className="pe-3">
                        這一天沒有安排行程，把最後的時間留給你，用自己的方式跟小島做最後的相處。五點半回到台東富岡漁港碼頭後，準備回到溫暖的家
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </section>

          <section className="container-fluid mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/o7tdrCI.png"
                alt="活動精彩瞬間"
                className="subtitle"
              />
            </h2>
            <ul className="d-none d-md-flex row ps-0">
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/dWFF2yK.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>

              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/l3kQxcr.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/s8RoIk4.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/iyzZEtG.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/o70ECFd.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/GOVo0Bu.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/fyTGfPO.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/MtzqjoL.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/QL4U80A.jpg"
                  alt="蘭嶼親子團活動照片"
                  className="w-100"
                />
              </li>
            </ul>
            <div
              id="activityCarouselControls"
              className="carousel slide d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/dWFF2yK.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/l3kQxcr.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/s8RoIk4.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/iyzZEtG.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/o70ECFd.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/GOVo0Bu.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/fyTGfPO.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/MtzqjoL.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
                <div className="carousel-item active">
                  <img
                    src="https://i.imgur.com/QL4U80A.jpg"
                    className="d-block w-100"
                    alt="蘭嶼親子團活動照片"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </section>

          <section className="infor-wrap">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/LdmlyrL.png"
                alt="活動資訊"
                className="infor-title"
              />
            </h2>
            <div className="container-fluid infor-bg">
              <div className="container">
                <ul className="infor-content py-5 ps-0">
                  <li className="mb-3 mb-md-5">
                    <h3>費用說明</h3>
                    <p>
                      （含活動費、活動期間食宿、租摩托車、保險，抵達台東富岡漁港碼頭的交通費不含）
                      <br />
                      每人 19800 元，12歲以下孩童18800元
                      <br />
                      三人團報同住一房，每人原價各折扣1000元
                      <br />
                      四人團報同住一房，每人原價各折扣1500元
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>交通</h3>
                    <p>
                      自行開車抵達台東富岡碼頭或自台東火車站拼車前往富岡碼頭
                      （若有需要，亦可在出發前的群組上揪團拼車）
                      <br /> 團費包含台東-蘭嶼 來回船票，四天三夜兩人一台摩托車
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>餐食</h3>
                    <p>部落媽媽的特色餐＋自食其力的餐點</p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>住宿</h3>
                    <p>
                      四天三夜住宿房型安排為雙人房，3-4位同住的話，亦有四人房型。
                      <br />
                      ＊小島的住宿均以乾淨舒服為主，大部分的時間都在戶外、在部落裡頭，感受小島的美。
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>注意事項</h3>
                    <ul className="ps-0">
                      <li className="d-flex">
                        <p className="mb-0">1、</p>
                        <p className="mb-0">
                          蘭嶼的美很不容易，因為距離遙遠、交通不便，所以達悟文化保留相對完整、環境開發程度也低，生活習慣跟禁忌上與台灣有許多不同的地方，因此抵達蘭嶼後請大家尊重在地文化，跟我們一起入境隨俗！
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">2、</p>
                        <p className="mb-0">
                          蘭嶼不小，環島一圈大約37公里，是台灣第二大的離島，島上有六個部落，我們抵達及入住的是朗島部落，每個部落的文化及語調都有些許不同，請注意自己的好奇心，不要任意地拿起相機對著族人、住家瘋狂拍照，或者未經允許進入傳統建築及碰觸TATALA
                          (拼板舟)。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">3、</p>
                        <p className="mb-0">
                          蘭嶼的移動都須仰賴機車代步，交通法規跟台灣一摸一樣，只是警察局較少開門而已，車速請放最慢，尤其進入部落時；
                          另外，島上到處是移動式紅綠燈，且路況不佳、充滿碎石，請小心騎車。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">4、</p>
                        <p className="mb-0">
                          蘭嶼海岸的珊瑚礁是最美麗的陷阱，深度落差大，暗流甚多，海上活動必須有在地嚮導帶領以策安全，下水前請檢查自身的安全設備，切勿獨自下水。(
                          想在哪下水務必先問過！)
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">5、</p>
                        <p className="mb-0">
                          此次活動主要給想更深入蘭嶼的你，行程很緩慢、沒有排得很滿，如果想要去潛水
                          ，Day2
                          從山林回來後，下午的時間留給大家好好感受小島，若需協助預定自費項目費用如下：
                          a.體驗潛水 2500元/人 b.體驗自潛 2500元/人 c.浮潛探索
                          500元/人 預約導潛、船潛等請在報名後另外私訊小編
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">6、</p>
                        <p className="mb-0">
                          下水請勿擦防曬油，會污染水源，也禁止踩踏、碰觸珊瑚。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">7、</p>
                        <p className="mb-0">
                          蘭嶼垃圾處理不易，請大家幫忙，盡量把自身製造的垃圾帶回大島處理！讓小島可以持續保持美麗！(
                          我們會準備垃圾袋給大家 ）
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">8、</p>
                        <p className="mb-0">
                          蘭嶼本島商家均採現金交易，只有紅頭部落的郵局、及東清部落的7-11有ATM提款機可用，若遇連假期間，島上可能會鬧錢荒
                          ( ATM被領光 )，建議在台灣先備好現金。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">9、</p>
                        <p className="mb-0">
                          需要準備之物品：個人盥洗用品（毛巾、牙刷、牙膏）、環保餐具、現金、雨具、遮陽帽、簡易藥品、玩水用具、泳褲、比基尼、礁鞋or運動涼鞋、玩水備用衣物、個人藥品、
                          健保卡、身分證、行動電源、備用電池、一至兩套不怕用髒的衣著。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 mb-md-5 d-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <h3>
                      承辦單位：
                      <a
                        href="https://profoundlifetw.com/"
                        target="_blank"
                        rel="noreferrer"
                        className="text-decoration-none"
                      >
                        好日常
                      </a>
                      <span className="h6">(心喬旅行社股份有限公司)</span>
                    </h3>
                    <a
                      href="https://profoundlife.rezio.shop/zh-TW/product/wildLanyu"
                      target="_blank"
                      className="btn-join btn-hover"
                      rel="noreferrer"
                    >
                      <img src={require("../img/join.png")} alt="我要報名" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
        <Footer />

        <GoToTop />
      </div>
    );
  }
}

export default LanyuPage;
