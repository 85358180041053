import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"; //import react-router-dom to use Router function
import Main from "./Main";
import PenghuPage from "./Travel/PenghuPage";
import LanyuPage from "./Travel/LanyuPage";
import HualienPage from "./Travel/HualienPage";
import QiguPage from "./Travel/QiguPage";
import NotFound from "./NotFound";
import Series from "./Series";
import Group from "./Group";
class App extends React.Component {
  // app contains all url of pages, path define the url while element define the js element
  render() {
    return (
      <Router>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route path="/Series/:id" element={<Series />} />
          <Route path="/Group" element={<Group />} />
          <Route path="/Travel">
            <Route path="" element={<HualienPage />} />
            <Route path="Penghu" element={<PenghuPage />} />
            <Route path="Lanyu" element={<LanyuPage />} />
            <Route path="Hualien" element={<HualienPage />} />
            <Route path="Qigu" element={<QiguPage />} />
          </Route>
          <Route element={<NotFound />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
