import React from "react";
import { Link } from "react-router-dom";
import "./TravelHeader.scss";
class Home extends React.Component {
  //function
  closeMenu() {
    const el = document.querySelector(".navbar-collapse");
    el.classList.toggle("show");
  }
  //render html
  render() {
    return (
      <header className="travel-header">
        <nav className="navbar navbar-dark navbar-expand-md fixed-top">
          <div className="container-fluid">
            <a
              href="/"
              className="nav-link navbar-brand brand img-hide d-md-none d-inline"
            >
              叫我野孩子
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#headerNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse justify-content-md-center justify-content-between"
              id="headerNavbar"
            >
              <ul className="navbar-nav">
                <li className="nav-item d-flex justify-content-center p-3 p-md-0 me-2">
                  <a
                    href="/Travel/Hualien"
                    className="nav-link nav-hualian img-hide"
                  >
                    花蓮親子團
                  </a>
                </li>
                <li className="nav-item d-flex justify-content-center p-3 p-md-0 mx-lg-4">
                  <a
                    href="/Travel/Penghu"
                    className="nav-link nav-penghu img-hide"
                  >
                    澎湖親子團
                  </a>
                </li>
              </ul>
              <h1>
                <a
                  href="/"
                  className="navbar-brand brand img-hide text-white me-0 d-none d-md-block"
                >
                  叫我野孩子
                </a>
              </h1>
              <ul className="navbar-nav">
                <li className="nav-item d-flex justify-content-center p-3 p-md-0 mx-lg-4">
                  <a
                    href="/Travel/Lanyu"
                    className="nav-link nav-lanyu img-hide"
                  >
                    蘭嶼親子團
                  </a>
                </li>
                <li className="nav-item d-flex justify-content-center p-3 p-md-0">
                  <a
                    href="/Travel/Qigu"
                    className="nav-link nav-qigu img-hide"
                  >
                    七股親子團
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Home;
