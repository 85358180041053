import React from "react";
import "./TravelShared.scss";
import "./QiguPage.scss";
import Header from "./TravelHeader";
import Footer from "./TravelFooter";
import GoToTop from "../GoToTop";
import ReactPlayer from "react-player";
class QiguPage extends React.Component {
  //function

  //render html
  render() {
    return (
      <div className="travel">
        <Header />
        <main className="qigu">
          <section className="header-video-container container-fluid vh-100 px-0 mb-5">
            <ReactPlayer
              url="https://www.youtube.com/embed/H1KP9eGnM8o?&autoplay=1&mute=1&controls=0&rel=1&loop=1&playlist=H1KP9eGnM8o"
              playing="true"
              muted="true"
              loop="true"
            />
            <h2>
              <img
                src="https://i.imgur.com/LhN1bqD.png"
                alt=""
                className="top-banner-title position-absolute top-50 start-50"
              />
            </h2>
          </section>

          <section className="container">
            <section className="row flex-md-row-reverse align-items-md-center mb-3 mb-md-0">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/6PRtWEM.png"
                    alt="找回遺失的五感"
                    className="subtitle"
                  />
                </h2>
                <p className="content">
                  常常聽孩子說我會釣魚啊，在電動裡按一個鈕就可以把魚釣起來喔！來到七股，你和孩子可以一起吹到海風、看到黑面琵鷺從頭頂飛過，在泥濘中摸到文蛤，不用虛擬實境，因為這裡所有的感受都是真的。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/COhBpPI.png"
                  alt="qigu-img1"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <section className="container-fluid px-0 py-3 py-md-0 my-5 my-sm-0 banner-bg d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col col-md-8">
                  <h2 className="pt-4 pt-md-0">
                    <img
                      src="https://i.imgur.com/u8Gdhrp.png"
                      alt="麥香魚是什麼魚"
                      className="w-50"
                    />
                  </h2>
                  <p className="content text-white">
                    有人問孩子：麥香魚是什麼魚做的，孩子覺得這個問題很笨，麥香魚當然是麥香魚做的，他不知道這個世界上根本沒有麥香魚這種魚。讓我們從餐桌帶領孩子認識大海吧，七股的魚塭風光就是最好的食魚教育體驗基地。
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container mb-5">
            <section className="row align-items-md-center">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/nvYONxT.png"
                    alt="成為一位小漁夫"
                    className="subtitle"
                  />
                </h2>
                <p className="content pe-md-5">
                  穿上青蛙裝的經驗是很特殊的，把自己泡在海水裡撈蝦、釣虱目魚、摸文蛤，認識這個由海水灌溉的天然養殖場，體驗台灣養殖漁業的辛苦，讓孩子對每一份職業都能升起尊敬之心。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/PA1Ryrx.png"
                  alt="qigu-img3"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <div className="mb-5">
            <img
              src="https://i.imgur.com/TkXLJhP.png"
              alt="split-line"
              className="w-100"
            />
          </div>

          <section className="container-fluid px-0 mb-5 text-center">
            <div className="container">
              <h2 className="d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/Av8u3Ty.png"
                  alt="黃澄澄的沙漠"
                  className="subtitle"
                />
              </h2>
              <p className="content">
                有聽說過台灣有沙漠嗎？讓我們搭上當地船長駕駛的漁船，在平靜無波的潟湖中穿梭，再到台灣最西邊
                <br className="d-none d-lg-block" />
                的國聖燈塔體驗什麼叫沙漠，夕陽餘暉下看著孩子盡興玩沙，驚覺人本來就是大自然的一分子啊！
              </p>
            </div>
            <iframe
              className="w-100 vh-100 video1"
              src="https://www.youtube.com/embed/DcxaF_o1c-8"
              title="qigu video 1"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </section>

          <section className="container mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/1dINU7W.png"
                alt="行程規劃"
                className="travel-title mb-3"
              />
            </h2>
            <ul className="ps-0 travel-list">
              <li className="travel-bg-1 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-4 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 1 <span>七股魚塭探險</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">穿上青蛙裝摸文蛤</h4>
                      <p className="pe-3">
                        十點半前六孔碼頭遊客中心集合，在阿月海產粥吃完午餐後到南灣生態養殖場，由教練帶領摸文蛤與撈海龍鬚菜體驗。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">包船遊潟湖 沙漠玩沙</h4>
                      <p className="pe-3">
                        下午前往南灣碼頭遊憩區，搭國泰2號遊潟湖、國聖燈塔玩沙(自備滑沙板或紙板)、無人島走步道、船上烤蚵看夕陽，認識充滿生態的美麗潟湖。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">夜間生態觀察</h4>
                      <p className="pe-3">
                        正阿芬海產店吃完晚餐後前往城西社區，由教練帶領進行暗蟬與陸蟹的生態觀察(自備頭燈)，認識這群可愛的小生物。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-2 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 2 <span>原來海鮮是這樣來的</span>
                  </h3>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2 my-md-4"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">釣虱目魚體驗</h4>
                      <p className="pe-3">
                        民宿早餐後，進行養殖漁業歷史導覽、和孩子一起釣魚、練習清理漁獲、真空包裝，最後進行虱目魚蓋印餐墊創作。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">白蝦養殖體驗</h4>
                      <p className="pe-3">
                        中午用過便當後，在純海水的天然養殖場用傘網收蝦、幫蝦子做SPA、進行分級，然後DIY野菜海鮮料理、文蛤湯和涼拌海龍鬚菜，享受自己辛苦的成果。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">回程</h4>
                      <p className="pe-3">
                        帶著七股的海水味和滿滿的收穫，準備回到溫暖的家
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </section>

          <section className="container-fluid mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/o7tdrCI.png"
                alt="活動精彩瞬間"
                className="subtitle"
              />
            </h2>
            <ul className="d-none d-md-flex row ps-0">
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/gqRr57z.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>

              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/aqvwJXd.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/18tBYQc.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/JYM7ncA.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/K54bJm8.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/0AZqKOo.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/nFwMvVY.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/5o9CoI1.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/HDQ1UPt.jpg"
                  alt="七股親子團活動照片"
                  className="w-100"
                />
              </li>
            </ul>
            <div
              id="activityCarouselControls"
              className="carousel slide d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/gqRr57z.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/aqvwJXd.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/18tBYQc.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/JYM7ncA.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/K54bJm8.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/0AZqKOo.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/nFwMvVY.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/5o9CoI1.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
                <div className="carousel-item active">
                  <img
                    src="https://i.imgur.com/HDQ1UPt.jpg"
                    className="d-block w-100"
                    alt="七股親子團活動照片"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </section>

          <section className="infor-wrap">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/LdmlyrL.png"
                alt="活動資訊"
                className="infor-title"
              />
            </h2>
            <div className="container-fluid infor-bg">
              <div className="container">
                <ul className="infor-content py-5 ps-0">
                  <li className="mb-3 mb-md-5">
                    <h3>費用說明</h3>
                    <p>
                      一人7000元（含活動費、活動期間食宿、保險，不含租車費用）
                      <br />
                      三人團報同住一房，每人原價各折扣1000元
                      <br />
                      四人團報同住一房，每人原價各折扣1500元
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>交通</h3>
                    <p>自行開車</p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>餐食</h3>
                    <p>以海鮮為主，包含在團費中，素食者要先註明</p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>住宿</h3>
                    <p>七股當地民宿</p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>注意事項</h3>
                    <ul className="ps-0">
                      <li className="d-flex">
                        <p className="mb-0">1、</p>
                        <p className="mb-0">
                          【適合對象】建議8歲以上參加，若12歲以下需有家長陪同(陪同家長須一起參加)，活動多戶外行程建議衡量個人體能後報名參加。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">2、</p>
                        <p className="mb-0">
                          【衣著建議】戶外行程多為自然環境，請著「長袖長褲」衣著，並注意防蚊、防曬。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">3、</p>
                        <p className="mb-0">
                          【保險聲明】本活動參加者將投保旅行平安保險，而15歲以下孩童因受保險法107條修正案影響，所有保單合計喪葬費用不得超過61.5萬元，若既有之保單已達金額上限，本次活動將不受理保險，若未足上限金額將依現行規定辦理保險。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">4、</p>
                        <p className="mb-0">
                          【活動小叮嚀】
                          <ul className="list-dotted">
                            <li>
                              文蛤活動有下魚塭體驗行程，會提供青蛙裝給各位，穿著青蛙裝僅需脫鞋(可穿襪子)。
                              穿著青蛙裝可防水，但因天氣炎熱穿著青蛙裝較悶，可能會有流汗的情形，建議可多帶一套換洗衣物與毛巾、拖鞋備用。場域有簡單沖洗水龍頭，給大家上岸後使用。
                            </li>
                            <li>
                              陸蟹暗蟬夜觀行程，因可能踩踏防風林泥濘土地，建議可穿著雨鞋。
                            </li>
                            <li>
                              虱目魚活動有魚塭釣魚體驗及殺魚教學體驗，會使用刀具與魚鉤等器材，若有孩童同行之家庭，請家長於體驗活動時全程陪同，共同注意孩童安全。
                            </li>
                          </ul>
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">5、</p>
                        <p className="mb-0">
                          【防疫措施】為配合臺南市防疫政策，請於活動前配合量測體溫、酒精消毒雙手，並於室內進行小講堂課程時敬請配戴口罩，盡量保持社交安全距離。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">6、</p>
                        <p className="mb-0">
                          【建議攜帶物品】換洗衣物、毛巾、遮陽帽、拖鞋、雨鞋、頭燈、雨具、防曬與防風衣物、防蚊用品、環保杯。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 mb-md-5 d-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <h3>承辦單位：股份魚鄉</h3>
                    <a
                      href="http://cofishland.com.tw/onlineOrder/tour/tour_page.php?touritem_no=TI012"
                      className="btn-join btn-hover"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={require("../img/join.png")} alt="我要報名" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        <GoToTop />
      </div>
    );
  }
}

export default QiguPage;
