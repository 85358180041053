// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.penghu .banner-bg {
  background: url(https://i.imgur.com/vaWEc7c.png) no-repeat center/150%;
  height: 280px;
}
@media (min-width: 375px) {
  .penghu .banner-bg {
    background-size: 120%;
  }
}
@media (min-width: 568px) {
  .penghu .banner-bg {
    background-size: 110%;
    height: 350px;
  }
}
@media (min-width: 767px) {
  .penghu .banner-bg {
    background-size: 100%;
    height: 500px;
    width: 100%;
  }
}
@media (min-width: 920px) {
  .penghu .banner-bg {
    height: 800px;
  }
}
@media (min-width: 1600px) {
  .penghu .banner-bg {
    height: 900px;
  }
}
.penghu .travel-bg-1 {
  background: url(https://i.imgur.com/bzlFDpD.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.penghu .travel-bg-2 {
  background: url(https://i.imgur.com/4DTnZVi.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.penghu .travel-bg-3 {
  background: url(https://i.imgur.com/MlTGzjh.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.penghu .travel-bg-4 {
  background: url(https://i.imgur.com/thJAi8C.png) no-repeat 50% 50%/cover;
  min-height: 370px;
}
@media (min-width: 1200px) {
  .penghu .travel-bg-4 {
    background-size: 103%;
  }
}
.penghu .infor-bg {
  background: url(https://i.imgur.com/PZlTygn.png) no-repeat center center/cover;
}`, "",{"version":3,"sources":["webpack://./src/Travel/PenghuPage.scss"],"names":[],"mappings":"AACE;EACE,sEAAA;EACA,aAAA;AAAJ;AACI;EAHF;IAII,qBAAA;EAEJ;AACF;AADI;EANF;IAOI,qBAAA;IACA,aAAA;EAIJ;AACF;AAHI;EAVF;IAWI,qBAAA;IACA,aAAA;IACA,WAAA;EAMJ;AACF;AALI;EAfF;IAgBI,aAAA;EAQJ;AACF;AAPI;EAlBF;IAmBI,aAAA;EAUJ;AACF;AANI;EACE,wEAAA;EACA,iBAAA;AAQN;AANI;EACE,wEAAA;EACA,iBAAA;AAQN;AANI;EACE,wEAAA;EACA,iBAAA;AAQN;AANI;EACE,wEAAA;EACA,iBAAA;AAQN;AAPM;EAHF;IAII,qBAAA;EAUN;AACF;AALI;EACE,8EAAA;AAON","sourcesContent":[".penghu {\n  .banner-bg {\n    background: url(https://i.imgur.com/vaWEc7c.png) no-repeat center / 150%;\n    height: 280px;\n    @media (min-width: 375px) {\n      background-size: 120%;\n    }\n    @media (min-width: 568px) {\n      background-size: 110%;\n      height: 350px;\n    }\n    @media (min-width: 767px) {\n      background-size: 100%;\n      height: 500px;\n      width: 100%;\n    }\n    @media (min-width: 920px) {\n      height: 800px;\n    }\n    @media (min-width: 1600px) {\n      height: 900px;\n    }\n  }\n\n  .travel-bg {\n    &-1 {\n      background: url(https://i.imgur.com/bzlFDpD.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n    &-2 {\n      background: url(https://i.imgur.com/4DTnZVi.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n    &-3 {\n      background: url(https://i.imgur.com/MlTGzjh.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n    &-4 {\n      background: url(https://i.imgur.com/thJAi8C.png) no-repeat 50% 50% / cover;\n      min-height: 370px;\n      @media (min-width: 1200px) {\n        background-size: 103%;\n      }\n    }\n  }\n\n  .infor {\n    &-bg {\n      background: url(https://i.imgur.com/PZlTygn.png) no-repeat center center /\n        cover;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
