import React from "react";
import "./TravelShared.scss";
import "./HualienPage.scss";
import Header from "./TravelHeader";
import Footer from "./TravelFooter";
import GoToTop from "../GoToTop";
import ReactPlayer from "react-player";
class HualienPage extends React.Component {
  //function

  //render html
  render() {
    return (
      <div className="travel">
        <Header />
        <main className="hualien">
          <section className="header-video-container container-fluid vh-100 px-0 mb-5">
            <ReactPlayer
              url="https://www.youtube.com/embed/dp0geL8mzxU?&autoplay=1&mute=1&controls=0&rel=1&loop=1&playlist=dp0geL8mzxU"
              playing="true"
              muted="true"
              loop="true"
            />
            <div className="position-absolute top-50 start-50">
              <h2>
                <img
                  src="https://i.imgur.com/gLb96Di.png"
                  alt="讓孩子像森林學勇敢 小勇士體驗營"
                  className="top-banner-title"
                />
              </h2>
              <p className="text-white text-center top-banner-content mt-3">
                百年遷徙秘境、森林沈浸式場景、古蹟上的心靈體驗，
                讓自己置身真實的故事地景中，練技練心，找回勇氣
              </p>
            </div>
          </section>

          <section className="container">
            <section className="row flex-md-row-reverse align-items-md-center mb-3 mb-md-0">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/IYAMXTH.png"
                    alt="培養冒險的勇氣"
                    className="subtitle"
                  />
                </h2>
                <p className="content">
                  你有沒有發現自己常常對孩子說，甚至對自己說：「要小心」而不是「要勇敢」。當孩子四處探索、爬高爬低時，你選擇阻止，還是鼓勵他嘗試；當你面對冒險時，你會選擇鼓起勇氣，還是放棄？好奇心是珍貴的，放手到森林裡探索未知吧！海岸布農獵人會帶領進入森林的你，找到面對困難的勇氣。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/cVnSr3p.png"
                  alt="huadlian-img1"
                  className="w-100 f-100"
                />
              </div>
            </section>
            <section className="row align-items-md-center">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/6PRtWEM.png"
                    alt="找回遺失的五感"
                    className="subtitle"
                  />
                </h2>
                <p className="content">
                  海岸布農獵人進入山林前要先學習放空，回歸內在進入動物與植物的模式，學習自然的導引，包括山川、氣候、地形，棲地的深層認識，以交互運用在生活中。長期接觸電子產品與網路，聲光刺激讓我們都感到焦躁不安，有技巧地練習安靜，讓五感回到自己。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/5VPij1G.png"
                  alt="huadlian-img1"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <section className="container-fluid px-0 py-3 py-md-0 my-5 my-sm-0 banner-bg d-flex align-items-center">
            <div className="container">
              <div className="row">
                <div className="col col-md-8">
                  <h2>
                    <img
                      src="https://i.imgur.com/YvhJQZx.png"
                      alt="學會尊重生命"
                      className="w-50"
                    />
                  </h2>
                  <p className="content text-white">
                    參與狩獵的過程，有機會面對生命的逝去，那一刻食物不再只是食物，孩子會理解到生命是彼此交換、互相成全的過程，對生命將有多一份的慎重與尊敬。
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="container mb-5">
            <section className="row align-items-md-center">
              <div className="col-12 col-md-6">
                <h2>
                  <img
                    src="https://i.imgur.com/Zubadk9.png"
                    alt="鍛鍊生存的能力"
                    className="subtitle"
                  />
                </h2>
                <p className="content pe-md-5">
                  在森林裡學生火、辨認植物與採集，做出自己的餐具，煮飯給自己和同伴吃，營隊過程中，將安全地激發每個人在森林裡的生存能力。
                </p>
              </div>
              <div className="col-12 col-md-6">
                <img
                  src="https://i.imgur.com/m1j7SNm.png"
                  alt="huadlian-img3"
                  className="w-100 f-100"
                />
              </div>
            </section>
          </section>

          <div className="mb-5">
            <img
              src="https://i.imgur.com/hdttd5E.png"
              alt="split-line"
              className="w-100"
            />
          </div>

          <section className="container-fluid px-0 mb-5 text-center">
            <div className="container">
              <h2 className="d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/kURyUsA.png"
                  alt="成為自然的一部分"
                  className="subtitle"
                />
              </h2>
              <p className="content">
                不只是走馬看花、郊山式的自然體驗，來到高山森林基地，用大人與孩子都適用的方式，
                <br className="d-none d-lg-block" />
                學習野孩子般的方法，在森林裡玩耍、擲矛、爬上水藤盪鞦韆，不但釋放壓力，也能真正和自然融為一體。
              </p>
            </div>
            <iframe
              className="w-100 vh-100 video1"
              src="https://www.youtube.com/embed/sAggO-bz1OQ"
              title="huadlian video 1"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </section>

          <section className="container-fluid px-0 mb-5 text-center">
            <div className="container">
              <h2 className="d-flex justify-content-center">
                <img
                  src="https://i.imgur.com/sRfimAC.png"
                  alt="體驗全台唯一海岸布農族的百年遷移之路"
                  className="subtitle subtitle-lg"
                />
              </h2>
              <p className="content">
                全台灣唯一的海岸布農族，經過歷史田野調查後，終於呈現出來。大量遷移所處的環境，從深山進入淺山淺海，演變成特殊的經驗智慧的積累。海岸布農跟海洋族群撒奇萊雅族和阿美族技術彼此交流，從原本針葉林到低海拔闊葉林海岸森林生活，發展出獨特的山海共存生活方式，利用山林中的自然資源滿足生存需要，有著獨特的獵取與使用山林資源技巧。
                <br />
                「叫我野孩子
                小勇士海岸布農體驗營」三天兩夜獨特設計，完全處在遷移脈絡所處的遺跡路上，高至海岸山脈稜線上海拔700公尺，低至低海拔大白榕山谷溪流處，這是一趟古蹟上的心靈體驗，足以給人改變的方法和勇氣。
              </p>
            </div>
          </section>

          <section className="container mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/1dINU7W.png"
                alt="行程規劃"
                className="travel-title mb-3"
              />
            </h2>
            <p className="text-center content content-width-lg mb-3">
              沉浸在森林裡，學習海岸布農文化的智慧，三天二夜的獵場生命生活教育，鍛鍊提升心靈素質，
              找回內在的勇氣，成為【Mamangan】，成為自己，你是最獨特的。
            </p>
            <ul className="ps-0 travel-list">
              <li className="travel-bg-1 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 1 <span>Taisang 成為自然的一部分</span>
                  </h3>
                  <p className="text-center mb-0 fsz-sm content-width-sm">
                    轉換學習動物模式，行走野徑，感受森林溫度、風向、聲音、環境，與黑暗森林第一次接觸、
                    跟著動物們尋找黑夜中的美，練習安靜守住心。
                  </p>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">安頓身心 準備搭營</h4>
                      <p className="pe-3 fsz-sm">
                        十二點之前抵達高山森林基地，搭設營帳、聽部落傳承下來的故事，揹起屬於自己的裝備—傳統揹籃，融入百年前的海岸布農遷移之旅了解，準備開始在古蹟、古道上，解開傳說中的秘境任務。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">森林裡練心，學安靜</h4>
                      <p className="pe-3 fsz-sm">
                        把自己轉換成動物模式，在山稜線與山谷學動物安靜走路，製作夜間用的傳統火把，學習獵人科學，晚上吃獵人餐、自己燒柴洗熱水澡，準備與黑暗森林第一次接觸。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">夜探森林</h4>
                      <p className="pe-3 fsz-sm">
                        聽動物的叫聲、尋找黑夜中的美，將白天學習到的感官技能，與自己製作的火把，實際運用在黑暗的森林裡，讓心學會與黑暗共處的方式。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-2 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 2 <span>Tamasaz 堅持、提升力量</span>
                  </h3>
                  <p className="text-center mb-0">
                    打破自己的限制，鍛鍊森林裡的生活技能，學習獵場倫理，練技與練膽。
                  </p>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">練技，練身體的準心</h4>
                      <p className="pe-3 fsz-sm">
                        運用自然中的素材製作矛、羌笛與鑽木取火，將親手製作的獵人工具，實際運用在森林獵場中。學會進階的獵人科學，練習創造統合力並了解科學原理。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">學在森林生活的能力</h4>
                      <p className="pe-3 fsz-sm">
                        辨識與採集可食野菜，並親手製作餐具；尋找大自然中珍貴的香線驅蚊，認識動物生病時直覺啃食的民族植物草藥，成為草藥師。習得辨識生活用植物與民族植物的關鍵能力，以及冰河時期就存在的原生種植物與其用途。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">尋找動物發亮的眼睛</h4>
                      <p className="pe-3 fsz-sm">
                        等待入夜，透過感官追蹤動物，尋找森林裡發亮的眼睛，進行獵場倫理的生命教育，在寂靜的森林中感受動物與彼此的存在，並學會好好尊重每個生命。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
              <li className="travel-bg-3 row align-items-center mb-4 py-4 py-md-0">
                <div className="ms-0 ms-lg-5 col-12 col-sm-9 col-lg-8 col-xl-7">
                  <h3 className="text-center d-flex justify-content-evenly">
                    Day 3 <span>Mamangan 成為勇士叮嚀提醒自己的精神佩刀</span>
                  </h3>
                  <p className="text-center mb-0">
                    從森林裡練成勇氣，獲得獵人分享刀，那是對自己的祝福，重新成為自己。
                  </p>
                  <img
                    src="https://i.imgur.com/w66zxHl.png"
                    alt="travel-line"
                    className="w-100 my-2"
                  />
                  <ul className="ps-3 row">
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">向著光，取得勇氣</h4>
                      <p className="pe-3 fsz-sm">
                        前往勇氣石，迎接無際太平洋的東岸日出，重回百年前，海岸布農族經歷長途遷移後，首次看見大海的感動。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">一份象徵獵人的禮物</h4>
                      <p className="pe-3 fsz-sm">
                        海邊撿拾磨刀石，由長輩授與分享刀，代表成為勇士，取藤蔓編織刀鞘、靜心磨刀，獲得一份象徵獵人的禮物。百年老樹溪谷大白榕旁，在獵人的引導下收斂這幾天的所感所得，渡過勇士修練內心的最後時光。
                      </p>
                    </li>
                    <li className="col-12 col-lg-4">
                      <h4 className="h5">帶著勇氣與祝福</h4>
                      <p className="pe-3 fsz-sm">
                        享用完風味午餐後大家一起整理營地、拆除帳篷，下午三點鐘解散離營。
                      </p>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </section>

          <section className="container-fluid mb-5">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/o7tdrCI.png"
                alt="活動精彩瞬間"
                className="subtitle"
              />
            </h2>
            <ul className="d-none d-md-flex row ps-0">
              <li className="col-4">
                <img
                  src="https://i.imgur.com/0kJLouc.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/XWjC5Ig.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/dcjyolq.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/dq4exGK.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/h5M2qaP.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100 h-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/AqBkJKH.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4">
                <img
                  src="https://i.imgur.com/PS8eW01.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/pSl1Fgr.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/o7bdtZH.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/9wPXIoy.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/4O5dpfQ.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
              <li className="col-6 col-md-4 mb-3">
                <img
                  src="https://i.imgur.com/TW6RkXS.jpg"
                  alt="花蓮小勇士團活動照片"
                  className="w-100"
                />
              </li>
            </ul>
            <div
              id="activityCarouselControls"
              className="carousel slide d-md-none"
              data-bs-ride="carousel"
            >
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img
                    src="https://i.imgur.com/0kJLouc.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/XWjC5Ig.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/dcjyolq.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/dq4exGK.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/I9dAfBp.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/EpfzoE7.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/h5M2qaP.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/AqBkJKH.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/PS8eW01.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/pSl1Fgr.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/o7bdtZH.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/9wPXIoy.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/4O5dpfQ.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
                <div className="carousel-item" data-bs-interval="3000">
                  <img
                    src="https://i.imgur.com/TW6RkXS.jpg"
                    className="d-block w-100"
                    alt="花蓮小勇士團活動照片"
                  />
                </div>
              </div>
              <button
                className="carousel-control-prev"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="prev"
              >
                <span
                  className="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button
                className="carousel-control-next"
                type="button"
                data-bs-target="#activityCarouselControls"
                data-bs-slide="next"
              >
                <span
                  className="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </section>

          <section className="infor-wrap">
            <h2 className="d-flex justify-content-center">
              <img
                src="https://i.imgur.com/LdmlyrL.png"
                alt="活動資訊"
                className="infor-title"
              />
            </h2>
            <div className="container-fluid infor-bg">
              <div className="container">
                <ul className="infor-content py-5 ps-0">
                  <li className="mb-3 mb-md-5">
                    <h3>費用說明</h3>
                    <p>
                      每人新台幣16,000元（含三天兩夜活動費、食宿、保險、羌笛一組、獵人分享刀一組）
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>交通</h3>
                    <p>由家長自行接送至高山森林基地</p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>餐食</h3>
                    <p>部落媽媽的特色餐＋自食其力的餐點</p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>住宿</h3>
                    <p>
                      小勇士是4人一帳，帳篷設備為Snow
                      peak品牌露營器材，包含內外帳、薄地墊，一組折疊椅。
                    </p>
                  </li>
                  <li className="mb-3 mb-md-5">
                    <h3>活動注意事項</h3>
                    <ul className="ps-0">
                      <li className="d-flex">
                        <p className="mb-0">1、</p>
                        <p className="mb-0">
                          年齡：適合9到13歲的孩子參加（升四年級到升國二），也有親子一起參加的親子團可供報名。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">2、</p>
                        <p className="mb-0">
                          衣著：基地海拔150m與平地均溫，請參考氣象預報的建議選擇衣物外，下身避免短褲
                          ， 另請備連帽的風雨衣、雨鞋、防滑不怕髒的鞋子和拖鞋。
                          <br />
                          請帶足天數需要的衣物， 可能沒有時間洗衣服。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">3、</p>
                        <p className="mb-0">寢具：請自備睡袋與枕頭</p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">4、</p>
                        <p className="mb-0">
                          餐具：請自備碗、筷、茶飲杯和隨身水壺。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">5、</p>
                        <p className="mb-0">
                          體驗：請自備頭燈、簡易護目鏡、工作手套、雨衣和隨身小背包、筆、個人筆記。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">6、</p>
                        <p className="mb-0">
                          個人習慣藥品與健保卡、隨身小手電筒、毛巾、牙刷等盥洗物品，無小黑蚊一般防蚊即可。
                          另請備足口罩、隨身酒精乾洗手。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">7、</p>
                        <p className="mb-0">
                          請配合提供參加者的健康狀況調查問卷。
                        </p>
                      </li>
                      <li className="d-flex">
                        <p className="mb-0">8、</p>
                        <p className="mb-0">
                          純孩子團如果家長想陪同，只限晚上探視孩子，民宿推薦如下：後湖水月、海印、半月灣、海公主、
                          崖上、純粹愛大海。親子團則家長與孩子同住帳篷。
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li className="mb-3 mb-md-5 d-flex flex-column flex-md-row align-items-center justify-content-md-between">
                    <h3>承辦單位：高山森林基地</h3>
                    <ul className="ps-0">
                      <li className="mb-2">
                        <a
                          href="https://www.gs-forest.com/product/wildkid/"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-join-sm btn-hover"
                        >
                          <img
                            src="https://i.imgur.com/QESgQd4.png"
                            alt="幫孩子報名"
                          />
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          href="https://www.gs-forest.com/product/wildfamily/"
                          target="_blank"
                          rel="noreferrer"
                          className="btn-join-sm btn-hover"
                        >
                          <img
                            src="https://i.imgur.com/xFXJKuB.png"
                            alt="想跟孩子一起參加"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </main>
        <Footer />
        <GoToTop />
      </div>
    );
  }
}

export default HualienPage;
