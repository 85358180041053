import React from 'react';
import { useParams } from 'react-router-dom';
import data from './data/seriesDataList.json';
import './Series.scss';
import GoToTop from './GoToTop';
import SeriesHeader from './SeriesHeader';
import SeriesFooter from './SeriesFooter';
import ReactPlayer from 'react-player';
class Series extends React.Component {
  //constructor
  constructor(props) {
    super(props);
    this.id_from_other_page = this.props.info;
    this.pageData = data;
    this.state = {};
  }

  componentDidMount() {}

  //function
  renderVideo() {
    return (
      'https://www.youtube.com/embed/' +
      this.pageData[this.id_from_other_page]?.bannerVideo
    );
  }

  renderPhotoList() {
    const photoList = this.pageData[this.id_from_other_page]?.photoList.map(
      (photoLink, index) => (
        <li
          className={`carousel-item ${index === 0 ? `active` : ``}`}
          data-bs-interval="2500"
          key={index + 1}
        >
          <img
            src={photoLink}
            className="w-100"
            alt={this.pageData[this.id_from_other_page]?.series.title + '照片'}
          />
        </li>
      )
    );
    return photoList;
  }

  //render html
  render() {
    return (
      <div
        className={`series series-page ${
          this.pageData[this.id_from_other_page]?.id
        }`}
      >
        <SeriesHeader />
        <main>
          <ReactPlayer
            className="w-100 vh-100 video1"
            url={this.renderVideo()}
            playing="true"
            muted={this.pageData[this.id_from_other_page]?.bannerVideoMute}
            loop="true"
          />

          <section
            style={{
              backgroundImage: `url(${
                this.pageData[this.id_from_other_page]?.background
              })`,
            }}
            className="background"
          >
            <article className="main-section mx-auto custom-py">
              <div className="container">
                <div className="d-flex justify-content-center align-items-center mb-3">
                  <h2 className="text-center fw-bold display-5">
                    {this.pageData[this.id_from_other_page]?.series.number}{' '}
                    {this.pageData[this.id_from_other_page]?.series.title}{' '}
                    <span className={
                      this.pageData[this.id_from_other_page]?.series.subtitle
                        ? 'd-inline'
                        : 'd-none'
                    }>-</span><br className='d-sm-none'/>{' '}
                    {this.pageData[this.id_from_other_page]?.series.subtitle}
                  </h2>
                  <div className="video-link d-flex justify-content-center">
                    <a
                      href={this.pageData[this.id_from_other_page]?.videoLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        className="button button--rayen"
                        data-text="前往收看"
                      >
                        <span>前往收看</span>
                      </button>
                    </a>
                  </div>
                </div>

                <div className="font-content content mb-large mx-auto">
                  <p>
                    {this.pageData[this.id_from_other_page]?.series.content}
                  </p>
                </div>
              </div>
            </article>

            <article className="container d-lg-flex talking-section">
              <div className="row">
                <div className="col-12 col-lg-6 d-flex">
                  <div className="child-pic first">
                    <img
                      src={this.pageData[this.id_from_other_page]?.child[0].img}
                      alt={
                        this.pageData[this.id_from_other_page]?.child[0].name
                      }
                    />
                  </div>
                  <div className="talking-box">
                    <h3>
                      <img
                        src={
                          this.pageData[this.id_from_other_page]?.child[0]
                            .namePic
                        }
                        alt={
                          this.pageData[this.id_from_other_page]?.child[0].name
                        }
                        className="child-name"
                      />
                    </h3>
                    <p className="talking-content font-content">
                      {this.pageData[this.id_from_other_page]?.child[0].talking}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 d-flex">
                  <div className="child-pic second">
                    <img
                      src={this.pageData[this.id_from_other_page]?.child[1].img}
                      alt={
                        this.pageData[this.id_from_other_page]?.child[1].name
                      }
                    />
                  </div>
                  <div className="talking-box">
                    <h3>
                      <img
                        src={
                          this.pageData[this.id_from_other_page]?.child[1]
                            .namePic
                        }
                        alt={
                          this.pageData[this.id_from_other_page]?.child[1].name
                        }
                        className="child-name"
                      />
                    </h3>
                    <p className="talking-content font-content">
                      {this.pageData[this.id_from_other_page]?.child[1].talking}
                    </p>
                  </div>
                </div>
                <div className="col-12 d-flex coach">
                  <div
                    className={
                      this.pageData[this.id_from_other_page]?.id ===
                      'tastyWinter'
                        ? 'd-none'
                        : 'coach-pic'
                    }
                  >
                    <img
                      src={this.pageData[this.id_from_other_page]?.coach.img}
                      alt={this.pageData[this.id_from_other_page]?.coach.name}
                    />
                  </div>
                  <div
                    className={
                      this.pageData[this.id_from_other_page]?.id ===
                      'tastyWinter'
                        ? 'coach-pic d-md-flex'
                        : 'd-none'
                    }
                  >
                    <img
                      src={this.pageData[this.id_from_other_page]?.coach.img}
                      alt={this.pageData[this.id_from_other_page]?.coach.name}
                    />
                    <img src="https://i.imgur.com/0AIeuVx.png" alt="教練群2" />
                    <img src="https://i.imgur.com/HCQb5kJ.png" alt="教練群3" />
                  </div>
                  <div className="talking-box">
                    <h3>
                      <img
                        src={
                          this.pageData[this.id_from_other_page]?.coach.namePic
                        }
                        alt={this.pageData[this.id_from_other_page]?.coach.name}
                        className="coach-name"
                      />
                    </h3>
                    <p className="talking-content font-content">
                      {this.pageData[this.id_from_other_page]?.coach.talking}
                    </p>
                  </div>
                </div>
                <div
                  className={`col-12 d-flex coach ${
                    this.pageData[this.id_from_other_page]?.coach2?.name
                      ? 'd-block'
                      : 'd-none'
                  }`}
                >
                  <div className="coach-pic">
                    <img
                      src={this.pageData[this.id_from_other_page]?.coach2?.img}
                      alt={this.pageData[this.id_from_other_page]?.coach2?.name}
                    />
                  </div>
                  <div className="talking-box">
                    <h3>
                      <img
                        src={
                          this.pageData[this.id_from_other_page]?.coach2
                            ?.namePic
                        }
                        alt={
                          this.pageData[this.id_from_other_page]?.coach2?.name
                        }
                        className="coach-name"
                      />
                    </h3>
                    <p className="talking-content font-content">
                      {this.pageData[this.id_from_other_page]?.coach2?.talking}
                    </p>
                  </div>
                </div>
              </div>
            </article>

            <section>
              <h2 className="text-center fw-bold mb-3 display-5">節目照片</h2>
              <section className="container mb-5">
                <div
                  id="carouselExampleControls"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <ul className="carousel-inner ps-0">
                    {this.renderPhotoList()}
                  </ul>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleControls"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </section>
            </section>
          </section>
        </main>
        <SeriesFooter />
        <GoToTop />
      </div>
    );
  }
}

export default function (props) {
  const { id } = useParams();

  return <Series {...props} info={id} />; //assign params to this.props, with name of "info"
}
