import React from 'react';
import './SeriesShared.scss';
import './main.scss';
import GoToTop from './GoToTop';
import SeriesHeader from './SeriesHeader';
import SeriesFooter from './SeriesFooter';

class Main extends React.Component {
  //function
  //render html
  render() {
    return (
      <div className="series">
        <SeriesHeader />
        <main className="main">
          <div className="container-fluid px-0 mb-5 position-relative">
            <img
              src="https://i.imgur.com/C4Iormu.jpg"
              alt="首頁主視覺"
              className="main-banner-img w-100"
            />
            {/* <a
              href="https://www.linetv.tw/drama/13349/eps/1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={require('./img/lineTvQrCode.jpg')}
                alt="LINE TV QR CODE"
                className="position-absolute qr-code"
              />
            </a> */}
          </div>

          <article className="main-section main-section-img1 mx-auto mb-5">
            <div className="container">
              <h2 className="text-center fw-bold mb-3 display-5">
                其實每個人心中都住著一個野孩子
              </h2>
              <div className="font-content content mb-large mx-auto">
                <p>
                  我爬上樹枝隨風搖晃身體，坐進浪裡讓海水來回沖刷著屁屁，在深夜的山林找尋野獸亮亮的眼睛，爸爸媽媽，我把衣服弄很髒，但我，有一點驕傲。
                </p>
                <div className="d-md-flex">
                  《叫我野孩子》--
                  <div className="ms-2">
                    <p className="mb-0">
                      臺灣第一個讓孩子體驗自然生活的兒童實境節目
                    </p>
                    <p>跟著我們上山下海、一起去冒險吧！</p>
                  </div>
                </div>
              </div>
              <section className="d-flex justify-content-center">
                <iframe
                  className="trailer mb-5 border-radius-20"
                  src="https://www.youtube.com/embed/5TByeZlul6M"
                  title="Behind-the-scenes video"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </section>
            </div>
          </article>

          <article className="main-section main-section-img2 mx-auto mb-5">
            <div className="container">
              <h2 className="text-center fw-bold mb-3 display-5">
                來看「叫我野孩子」吧！
              </h2>
              <div className="content mx-auto mb-5">
                <p className="font-content">
                  3C兒童是這個世代的普遍現象，長期接觸手機、網路，讓我們的孩子出現焦慮和憂鬱的症狀，而著迷於使用社群平台，也開始對真實世界裡的事漠不關心。但大自然裡有一種特殊的魅力，可以讓人放下手機、放鬆心靈，有機會讓孩子打開五感去探索去覺察。你和你的孩子都沈迷3C嗎？你們有多久沒有走進大自然了呢？來看「叫我野孩子」吧！
                </p>
              </div>
              <h2 className="text-center fw-bold mb-3 display-5">
                叫我野孩子 第二季 <span>-</span>{' '}
                <span className="d-md-none">
                  <br />
                </span>{' '}
                在大自然中構築
              </h2>
              <div className="content mx-auto mb-5">
                <p className="font-content">
                  讓孩子自己動手蓋東西，透過在大自然裡練習構築，與土地有更深的互動，更加能掌握材料與工具。最終我們不是要孩子學會蓋房子，而是讓他們在過程當中找回動手做的渴望，同時激發創意，也鍛鍊了解決問題的能力。
                </p>
              </div>
              <ul className="row ps-0 series-container">
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/ClimbToTreetop"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/XD0c2IG.jpg"
                      alt="爬上樹梢"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第一集</h3>
                    <a href="/Series/ClimbToTreetop">
                      <img
                        src="https://i.imgur.com/UfUMIFe.png"
                        alt="爬上樹梢"
                        className="custom-width --small"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/SleepoverInWoods"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/oioyFnB.jpg"
                      alt="睡在森林裡"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第二集</h3>
                    <a href="/Series/SleepoverInWoods">
                      <img
                        src="https://i.imgur.com/oSEbbnu.png"
                        alt="睡在森林裡"
                        className="custom-width"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/HopInValley"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/GbYH0Zw.jpg"
                      alt="在溪谷跳躍"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第三集</h3>
                    <a href="/Series/HopInValley">
                      <img
                        src="https://i.imgur.com/wtiKtRV.png"
                        alt="在溪谷跳躍"
                        className="custom-width"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/BreezeOnRoof"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/i3O7i91.jpg"
                      alt="上屋頂吹風"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第四集</h3>
                    <a href="/Series/BreezeOnRoof">
                      <img
                        src="https://i.imgur.com/towh8Zv.png"
                        alt="上屋頂吹風"
                        className="custom-width"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/StrollInBambooGrove"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/w8ht251.jpg"
                      alt="走進竹林"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第五集</h3>
                    <a href="/Series/StrollInBambooGrove">
                      <img
                        src="https://i.imgur.com/fDkljWT.png"
                        alt="走進竹林"
                        className="custom-width --small"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/BefriendHounds"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/uemTyJj.jpg"
                      alt="和獵犬做朋友"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第六集</h3>
                    <a href="/Series/BefriendHounds">
                      <img
                        src="https://i.imgur.com/PlFBakD.png"
                        alt="和獵犬做朋友"
                        className="custom-width"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/MySecretBase"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/X9ZbKMT.jpg"
                      alt="我的祕密基地"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第七集</h3>
                    <a href="/Series/MySecretBase">
                      <img
                        src="https://i.imgur.com/Yew7D5R.png"
                        alt="我的祕密基地"
                        className="custom-width"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/ZooInMyHome"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/hJ3DfNJ.jpg"
                      alt="動物園在我家"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第八集</h3>
                    <a href="/Series/ZooInMyHome">
                      <img
                        src="https://i.imgur.com/VXIJraO.png"
                        alt="動物園在我家"
                        className="series-title"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </article>

          <article className="container recommend mb-5">
            <h2 className="text-center fw-bold display-5">名人推薦</h2>
            <p className="text-center mb-5">依姓氏筆畫排列</p>
            <ul className="row ps-0">
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/LwsuO5d.png"
                  alt="荒野保護協會榮譽理事長 李偉文"
                ></img>
                <section>
                  <h4>荒野保護協會榮譽理事長 | 李偉文</h4>
                  <p className="font-content">
                    我夢想著每一個在臺灣長大的孩子，都有機會感受到臺灣大自然的美好。這個節目給了我們如此的信心，也相信能成為引領孩子進入大自然，成為野孩子的契機。
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/jyHZucU.png"
                  alt="網路作家 我是大A"
                ></img>
                <section>
                  <h4>網路作家 | 我是大A</h4>
                  <p className="font-content">
                    《叫我野孩子》不只是大自然裡的孩子，也是你蹲下來跟孩子講話的樣子。看他們看到的、聽他們在說的，然後或許會像看著片子的我，被孩子的乾淨和善良打動。
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/3bjSalq.png"
                  alt="金曲創作樂團 旺福"
                ></img>
                <section>
                  <h4>金曲創作樂團 | 旺福</h4>
                  <p className="font-content">
                    超級開心有這樣的節目，為大小朋友介紹許多在都市不容易經歷的野外體驗，讓我們重新跟那個一直活在我們心中的野小孩一起出去玩吧！
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/G3v019p.png"
                  alt="藝人 浩子"
                ></img>
                <section>
                  <h4>藝人 | 浩子</h4>
                  <p className="font-content">
                    《種稻篇》中兩個都市來的小女孩，在故事的最後，在收成之後的田裡，這兩個小女孩用稻草蓋了一個小小的稻草屋，怡然自得的躺在大地的懷裡，聞著稻香。
                    這個時候如果有一隻大野狼想要試著把她們親手蓋的稻草屋子吹倒，我相信她們一定誓死捍衛這個屋子。
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/JmN6WN1.png"
                  alt="演員 鳳小岳"
                ></img>
                <section>
                  <h4>演員 | 鳳小岳</h4>
                  <p className="font-content">
                    用孩子的視角去重新提醒我們與大自然的關係，製作團隊捕捉到了那些意在不言中的生命片刻，讓我們在高壓快速的生活中有機會也去淨化自己，在敬畏大自然的同時，也給在成長中的孩子們一個呼吸的窗口。
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/Aiuex0F.png"
                  alt="藝人 歐陽靖"
                ></img>
                <section>
                  <h4>藝人 | 歐陽靖</h4>
                  <p className="font-content">
                    製作團隊用高規格、花了一年半的時間貼身紀錄，一切都是無劇本的真實反應！或許身為大人的我們也將被激起埋藏在心裡深處的『野性』…對大地感恩、勇於打破框架、踏實面對挑戰的『野性』。
                    這是我在今年最期待的實境節目！
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/uKN0s8l.png"
                  alt="公益平台文化基金會董事長 嚴長壽"
                ></img>
                <section>
                  <h4>公益平台文化基金會董事長 | 嚴長壽</h4>
                  <p className="font-content">
                    科技飛速發展，我們的孩子卻越來越失去和自然相處的能力。
                    《叫我野孩子》不但帶孩子重新學習人類最基本的與自然共處的能力，也在每一個探索中教導他們如何面對無知的恐懼、尊重自然且感恩惜福。
                  </p>
                </section>
              </li>
              <li className="col-12 col-lg-6 d-flex align-items-center mb-4">
                <img
                  className="me-2 w-25 w-lg-50"
                  src="https://i.imgur.com/vFbajUm.png"
                  alt="叫我野孩子製作人 陳芝安"
                ></img>
                <section>
                  <h4>《叫我野孩子》製作人 | 陳芝安</h4>
                  <p className="font-content">
                    叫我野孩子播出後，萬萬沒有料到一個兒童節目竟然會激發孩子、大人，從看似遊玩、冒險的體驗過程中，碰觸更深刻的命題。
                  </p>
                </section>
              </li>
            </ul>
          </article>

          <div className="container-fluid px-0 mb-5 position-relative">
            <img
              src="https://i.imgur.com/D470cyo.png"
              alt="首頁主視覺"
              className="w-100"
            />
          </div>

          <article className="main-section main-section-img2 mx-auto mb-5">
            <div className="container">
              <h2 className="text-center fw-bold mb-3 display-5">
                叫我野孩子 第一季 <span>-</span>{' '}
                <span className="d-md-none">
                  <br />
                </span>{' '}
                從自然中取得食物
              </h2>
              <div className="content mx-auto mb-5">
                <p className="font-content">
                  以人類取得食物的發展軌跡為背景，讓孩子在大自然裡一邊取得食物一邊進行生活體驗，成為野孩子的過程中，不只鼓勵他們回歸自然，更希望孩子打開感官、用身體去感受，只要願意，大自然就有機會帶領我們打破現有框架，
                  突破自我。
                </p>
              </div>
              <ul className="row ps-0 series-container">
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/Gathering"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/5obJlTb.jpg"
                      alt="採集"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第一集</h3>
                    <a href="/Series/Gathering">
                      <img
                        src="https://i.imgur.com/EeR4XB4.png"
                        alt="採集"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/Hunting"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/fgJ4USW.jpg"
                      alt="狩獵"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第二集</h3>
                    <a href="/Series/Hunting">
                      <img
                        src="https://i.imgur.com/IRLLuRV.png"
                        alt="狩獵"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/LifeByTheSea"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/X6WTUAU.jpg"
                      alt="靠海生活"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第三集</h3>
                    <a href="/Series/LifeByTheSea">
                      <img
                        src="https://i.imgur.com/AhZbW14.png"
                        alt="靠海生活"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/IslandLife"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/wCLhB5j.jpg"
                      alt="小島生活"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第四集</h3>
                    <a href="/Series/IslandLife">
                      <img
                        src="https://i.imgur.com/4smaGZv.png"
                        alt="小島生活"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/GrowingRice"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/PLCU0jA.jpg"
                      alt="種稻"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第五集</h3>
                    <a href="/Series/GrowingRice">
                      <img
                        src="https://i.imgur.com/79OO28c.png"
                        alt="種稻"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/FarmingLife"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/ggo5sO7.jpg"
                      alt="農家"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第六集</h3>
                    <a href="/Series/FarmingLife">
                      <img
                        src="https://i.imgur.com/gsng6zL.png"
                        alt="農家"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/Fishpond"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/Z4VxCG7.jpg"
                      alt="魚塭"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第七集</h3>
                    <a href="/Series/Fishpond">
                      <img
                        src="https://i.imgur.com/ipleEoF.png"
                        alt="魚塭"
                        className="w-100"
                      />
                    </a>
                  </div>
                </li>
                <li className="col-12 col-md-6">
                  <a
                    href="/Series/TastyWinter"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src="https://i.imgur.com/o3OWvRm.jpg"
                      alt="冬天的滋味"
                      className="series-img d-inline-block mb-3 w-100 border-radius-20"
                    />
                  </a>
                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <h3 className="me-3">第八集</h3>
                    <a href="/Series/TastyWinter">
                      <img
                        src="https://i.imgur.com/vx3bB6w.png"
                        alt="冬天的滋味"
                        className="series-title"
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </article>
        </main>
        <SeriesFooter />
        <GoToTop />
      </div>
    );
  }
}

export default Main;
