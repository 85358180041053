import React from "react";
import "./SeriesShared.scss";
import "./Group.scss";
import GoToTop from "./GoToTop";
import SeriesHeader from "./SeriesHeader";
import SeriesFooter from "./SeriesFooter";

class Group extends React.Component {
  //function

  //render html
  render() {
    return (
      <div className="series group-page">
        <SeriesHeader />
        <main className="group">
          <section className="container pt-3">
            <div className="mb-5">
              <h2 className="h1 text-center mb-3">幕後花絮</h2>
              <iframe
                className="w-100 vh-100 video1"
                src="https://www.youtube.com/embed/2zmzH8eZ4hU"
                title="Behind-the-scenes video"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
            <article className="group-infor d-flex flex-column mx-auto mb-100">
              <h2 className="h1 mb-3 text-center">團隊介紹</h2>
              <p className="font-content group-infor-content mx-auto mb-5">
                「叫我野孩子」一共花了一年半的時間製作，過程的艱難與喜悅都如實呈現在影片中，很希望觀眾能和我們一起感動、一起大笑、一起流淚，相信你們也會收穫滿滿的。
              </p>
              <ul className="font-content row">
                <li className="col-6 col-md-4 d-sm-flex align-items-center mb-3">
                  <img
                    className="group-member-img mb-2 mb-sm-0"
                    src="https://i.imgur.com/oJEPvFI.png"
                    alt="金鐘製作人-陳芝安"
                  />
                  <div className="border-start border-dark border-2 ms-sm-3 ps-2">
                    <h4 className="h5">金鐘製作人</h4>
                    <h3 className="h2">陳芝安</h3>
                  </div>
                </li>
                <li className="col-6 col-md-4 d-sm-flex align-items-center mb-3">
                  <img
                    className="group-member-img mb-2 mb-sm-0"
                    src="https://i.imgur.com/UbaebDm.png"
                    alt="金鐘導演-謝欣志"
                  />
                  <div className="border-start border-dark border-2 ms-sm-3 ps-2">
                    <h4 className="h5">金鐘 導演</h4>
                    <h3 className="h2">謝欣志</h3>
                  </div>
                </li>
                <li className="col-6 col-md-4 d-sm-flex align-items-center mb-3">
                  <img
                    className="group-member-img mb-2 mb-sm-0"
                    src="https://i.imgur.com/iuETM0Q.png"
                    alt="攝影、企劃-潘信安"
                  />
                  <div className="border-start border-dark border-2 ms-sm-3 ps-2">
                    <h4 className="h5">攝影、企劃</h4>
                    <h3 className="h2">潘信安</h3>
                  </div>
                </li>
                <li className="col-6 col-md-4 d-sm-flex align-items-center mb-3">
                  <img
                    className="group-member-img mb-2 mb-sm-0"
                    src="https://i.imgur.com/MtLTNIH.png"
                    alt="企劃-李欣澄"
                  />
                  <div className="border-start border-dark border-2 ms-sm-3 ps-2">
                    <h4 className="h5">企劃</h4>
                    <h3 className="h2">李欣澄</h3>
                  </div>
                </li>
                <li className="col-6 col-md-4 d-sm-flex align-items-center mb-3">
                  <img
                    className="group-member-img mb-2 mb-sm-0"
                    src="https://i.imgur.com/Mgu9r6u.png"
                    alt="攝影助理-吳殷漢"
                  />
                  <div className="border-start border-dark border-2 ms-sm-3 ps-2">
                    <h4 className="h5">攝影 助理</h4>
                    <h3 className="h2">吳殷漢</h3>
                  </div>
                </li>
                <li className="col-6 col-md-4 d-sm-flex align-items-center mb-3">
                  <img
                    className="group-member-img mb-2 mb-sm-0"
                    src="https://i.imgur.com/zc2ZqCO.png"
                    alt="攝影助理-羅昱文"
                  />
                  <div className="border-start border-dark border-2 ms-sm-3 ps-2">
                    <h4 className="h5">攝影 助理</h4>
                    <h3 className="h2">羅昱文</h3>
                  </div>
                </li>
              </ul>
            </article>

            <section className="container banner mb-5">
              <h2 className="h1 text-center mb-3">幕後照片</h2>
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-bs-ride="carousel"
              >
                <ul className="carousel-inner ps-0">
                  <li className="carousel-item active" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/NExNUog.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/UGe5EIf.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/L97a3b7.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/15IWYI0.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/M1U0UL6.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/jS4IucE.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/sPJ0WuB.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/32YmCtD.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                  <li className="carousel-item" data-bs-interval="2500">
                    <img
                      src="https://i.imgur.com/eOECFQA.jpg"
                      className="d-block w-100"
                      alt="幕後照片"
                    />
                  </li>
                </ul>
                <button
                  className="carousel-control-prev"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="prev"
                >
                  <span
                    className="carousel-control-prev-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button
                  className="carousel-control-next"
                  type="button"
                  data-bs-target="#carouselExampleControls"
                  data-bs-slide="next"
                >
                  <span
                    className="carousel-control-next-icon"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Next</span>
                </button>
              </div>
            </section>

            <section className="container logo-video">
              <div className="row">
                <div className="col-12 col-md-7 logo-video-container mb-3">
                  <iframe
                    src="https://www.youtube.com/embed/Ogi_ON50BSg"
                    title="YouTube video player"
                    frameborder="0"
                    allowfullscreen
                  ></iframe>
                </div>
                <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
                  <p className="h4 mb-3">
                    有任何影片包場、旅遊合作、拍攝案請寫信給我們，感謝您！
                  </p>
                  <p className="h5">
                    email:
                    <a
                      href="mailto:callmewildchild.official@gmail.com"
                      className="word-wrap text-decoration-none ms-2"
                    >
                      callmewildchild.official@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </section>
          </section>
        </main>
        <SeriesFooter />
        <GoToTop />
      </div>
    );
  }
}

export default Group;
