import React from 'react';
import './SeriesShared.scss';
class SeriesHeader extends React.Component {
  // variable
  dropdownList1 = [
    {
      id: 'Gathering',
      title: '採集',
    },
    {
      id: 'Hunting',
      title: '狩獵',
    },
    {
      id: 'LifeByTheSea',
      title: '靠海生活',
    },
    {
      id: 'IslandLife',
      title: '小島人生',
    },
    {
      id: 'GrowingRice',
      title: '種稻',
    },
    {
      id: 'FarmingLife',
      title: '農家',
    },
    {
      id: 'Fishpond',
      title: '魚塭',
    },
    {
      id: 'TastyWinter',
      title: '冬天的滋味',
    },
  ];

  dropdownList2 = [
    {
      id: 'ClimbToTreetop',
      title: '爬上樹梢',
    },
    {
      id: 'SleepoverInWoods',
      title: '睡在森林裡',
    },
    {
      id: 'HopInValley',
      title: '在溪谷跳躍',
    },
    {
      id: 'BreezeOnRoof',
      title: '上屋頂吹風',
    },
    {
      id: 'StrollInBambooGrove',
      title: '走進竹林',
    },
    {
      id: 'BefriendHounds',
      title: '和獵犬做朋友',
    },
    {
      id: 'MySecretBase',
      title: '我的祕密基地',
    },
    {
      id: 'ZooInMyHome',
      title: '動物園在我家',
    },
  ];
  //function
  renderDropdownList(dropdownList) {
    return dropdownList.map((item) => (
      <a href={`/Series/${item.id}`} className="dropdown-item">
        {item.title}
      </a>
    ));
  }
  //render html
  render() {
    return (
      <header className="series-header main-header">
        <nav className="navbar navbar-expand navbar-dark bg-transparent">
          <div className="container-fluid justify-content-end">
            <h1 className="navbar-brand d-none" href="#">
              叫我野孩子
            </h1>
            <ul className="navbar-nav flex-row">
              <li className="nav-item">
                <a href="/" className="nav-link active">
                  首頁
                </a>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="seriesDropdownList"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  第一季
                  <br className="custom-word-wrap" />
                  分集介紹
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="seriesDropdownList"
                >
                  {this.renderDropdownList(this.dropdownList1)}
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="seriesDropdownList"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  第二季
                  <br className="custom-word-wrap" />
                  分集介紹
                </a>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="seriesDropdownList"
                >
                  {this.renderDropdownList(this.dropdownList2)}
                </ul>
              </li>
              <li className="nav-item dropdown d-md-none">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="moreDropdownList"
                  role="button"
                  data-bs-toggle="dropdown"
                >
                  更多
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="moreDropdownList"
                >
                  <a href="/Travel" className="dropdown-item">
                    體驗行程
                  </a>
                  <a
                    href="https://wabay.tw/projects/callmewildchild?locale=zh-TW&preview_token=iLFNv88LHZwJz2fBp5Frse8c"
                    className="dropdown-item"
                  >
                    募資計畫
                  </a>
                  <a href="/Group" className="dropdown-item">
                    製作團隊
                  </a>
                </ul>
              </li>
              <li className="nav-item d-none d-md-block">
                <a href="/Travel" className="nav-link">
                  體驗行程
                </a>
              </li>
              <li className="nav-item d-none d-md-block">
                <a
                  className="nav-link"
                  href="https://wabay.tw/projects/callmewildchild?locale=zh-TW&preview_token=iLFNv88LHZwJz2fBp5Frse8c"
                  target="_blank"
                  rel="noreferrer"
                >
                  募資計畫
                </a>
              </li>
              <li className="nav-item d-none d-md-block">
                <a href="/Group" className="nav-link">
                  製作團隊
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

export default SeriesHeader;
