// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lanyu .banner-bg {
  background: url(https://i.imgur.com/eEwlwng.png) no-repeat center/150%;
  height: 310px;
}
@media (min-width: 375px) {
  .lanyu .banner-bg {
    background-size: 120%;
  }
}
@media (min-width: 568px) {
  .lanyu .banner-bg {
    background-size: 110%;
    height: 350px;
  }
}
@media (min-width: 767px) {
  .lanyu .banner-bg {
    background-size: 100%;
    height: 500px;
    width: 100%;
  }
}
@media (min-width: 920px) {
  .lanyu .banner-bg {
    height: 800px;
  }
}
@media (min-width: 1600px) {
  .lanyu .banner-bg {
    height: 970px;
  }
}
.lanyu .travel-bg-1 {
  background: url(https://i.imgur.com/GFrCxq3.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.lanyu .travel-bg-2 {
  background: url(https://i.imgur.com/7fU42Xs.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.lanyu .travel-bg-3 {
  background: url(https://i.imgur.com/jOdC52R.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.lanyu .travel-bg-4 {
  background: url(https://i.imgur.com/qxIyNUI.png) no-repeat 50% 50%/cover;
  min-height: 356px;
}
.lanyu .infor-bg {
  background: url(https://i.imgur.com/AVsSrtQ.png) no-repeat center center/cover;
}`, "",{"version":3,"sources":["webpack://./src/Travel/LanyuPage.scss"],"names":[],"mappings":"AACE;EACE,sEAAA;EACA,aAAA;AAAJ;AACI;EAHF;IAII,qBAAA;EAEJ;AACF;AADI;EANF;IAOI,qBAAA;IACA,aAAA;EAIJ;AACF;AAHI;EAVF;IAWI,qBAAA;IACA,aAAA;IACA,WAAA;EAMJ;AACF;AALI;EAfF;IAgBI,aAAA;EAQJ;AACF;AAPI;EAlBF;IAmBI,aAAA;EAUJ;AACF;AAPI;EACE,wEAAA;EACA,iBAAA;AASN;AAPI;EACE,wEAAA;EACA,iBAAA;AASN;AAPI;EACE,wEAAA;EACA,iBAAA;AASN;AAPI;EACE,wEAAA;EACA,iBAAA;AASN;AAJI;EACE,8EAAA;AAMN","sourcesContent":[".lanyu {\n  .banner-bg {\n    background: url(https://i.imgur.com/eEwlwng.png) no-repeat center / 150%;\n    height: 310px;\n    @media (min-width: 375px) {\n      background-size: 120%;\n    }\n    @media (min-width: 568px) {\n      background-size: 110%;\n      height: 350px;\n    }\n    @media (min-width: 767px) {\n      background-size: 100%;\n      height: 500px;\n      width: 100%;\n    }\n    @media (min-width: 920px) {\n      height: 800px;\n    }\n    @media (min-width: 1600px) {\n      height: 970px;\n    }\n  }\n  .travel-bg {\n    &-1 {\n      background: url(https://i.imgur.com/GFrCxq3.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n    &-2 {\n      background: url(https://i.imgur.com/7fU42Xs.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n    &-3 {\n      background: url(https://i.imgur.com/jOdC52R.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n    &-4 {\n      background: url(https://i.imgur.com/qxIyNUI.png) no-repeat 50% 50% / cover;\n      min-height: 356px;\n    }\n  }\n\n  .infor {\n    &-bg {\n      background: url(https://i.imgur.com/AVsSrtQ.png) no-repeat center center /\n        cover;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
