import React from "react";
import { Link } from "react-router-dom";
import "./TravelFooter.scss";

class Footer extends React.Component {
  //function

  //render html
  render() {
    return (
      <div className="d-flex flex-column-reverse flex-md-row justify-content-between align-items-center my-3 px-3 px-lg-5">
        <p>
          Copyright©Lumian Production House <br /> All rights reserved.
        </p>
        <div className="d-md-flex mb-3">
          <Link to="/" className="d-block d-flex justify-content-center">
            <img
              src="https://i.imgur.com/MUoT2TT.png"
              alt="footer"
              className="footer-wildchild me-sm-4 w-100"
            ></img>
          </Link>
          <section>
            <h4 className="mb-3 h5 text-center">追蹤 叫我野孩子</h4>
            <div className="d-flex justify-content-center justify-content-lg-between">
              <a
                href="https://www.facebook.com/profile.php?id=100076585296855"
                target="_blank"
                rel="noreferrer"
                className="d-inline-block"
              >
                <img
                  src="https://i.imgur.com/ytv2tQr.png"
                  alt="icon-facebook"
                  className="icon"
                />
              </a>
              <a
                href="https://www.instagram.com/invites/contact/?i=1tyo7qlx4qce8&utm_content=nhzdnpb"
                target="_blank"
                className="d-inline-block"
                rel="noreferrer"
              >
                <img
                  src="https://i.imgur.com/NMYJFlK.png"
                  alt="icon-instagram"
                  className="icon"
                />
              </a>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default Footer;
