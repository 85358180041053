// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-wildchild {
  max-width: 300px;
}

.icon {
  width: 70px;
  padding: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/Travel/TravelFooter.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,eAAA;AACF","sourcesContent":[".footer-wildchild {\n  max-width: 300px;\n}\n\n.icon {\n  width: 70px;\n  padding: 0 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
